import React from "react"
import Layout from "../components/layout"
import Contact from "../components/contact"
import SeoBasic from "../components/seo"
import Seo from 'gatsby-plugin-wpgraphql-seo';
import { graphql } from "gatsby"

/*
https://placidowang.medium.com/how-to-add-an-email-form-to-your-react-website-for-free-using-emailjs-and-react-hook-form-7267d6365291
https://seifi.org/reactjs/build-a-contact-form-in-gatsby-part-2-react-hook-form.html
https://www.freecodecamp.org/news/build-forms-in-react-with-react-hook-form-library/
*/

export const query = graphql`
  query {
    seoPage:wpPage(slug: {eq: "contact-us"}) {
    nodeType
    title
    uri
    seo {
        title
        metaDesc
        focuskw
        metaKeywords
        metaRobotsNoindex
        metaRobotsNofollow
        opengraphTitle
        opengraphDescription
        opengraphImage {
            altText
            sourceUrl
            srcSet
        }
        twitterTitle
        twitterDescription
        twitterImage {
            altText
            sourceUrl
            srcSet
        }
        canonical
        cornerstone
        schema {
            articleType
            pageType
            raw
        }
    }
    }
  }
`

const IndexPage = ({
  data: {
    seoPage
  },
}) => {

  return(
  <Layout>
    {seoPage ?
      <Seo post={seoPage} />
    :
      <SeoBasic title="Contact" />
    }
    
    <div className="contact">
    <Contact />
    </div>
   
  </Layout>
)
}

export default IndexPage
